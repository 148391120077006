.config-head {
    padding: 5px 60px;
}

.config-head .title h1 {
    border: 1px solid;
    color: #00B1F0;
    background-color: #fff;
    font-size: 13px !important;
    border-radius: 15px;
    border: 1px solid #00B1F0;
    width: 80%;
    margin-right: 20px;
}

.config-head .title p {
    color: #475467;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-top: -5px;
}

.single-line {
    white-space: nowrap;
}

.txt-right {
    text-align: right;
}

.config-head .ant-input-affix-wrapper {
    border-radius: 6px;
    height: 35px;
    margin-right: 20px;
    margin-top: 20px;
    max-width: 200px;
}

a {
    color: #333;
    text-decoration: none;
}

.config-head .ant-btn,
.config-head .black {
    color: #fff;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: 38px;
}

.config-head .ant-btn {
    /* background: #3166ff; */
    border-radius: 15px;
    color: #00b1f0;
    border: 1px solid;
}

.config-head .ant-btn .anticon-plus-square {
    color: #00b1f0;

}

.config-head img {
    padding-right: 10px;
    width: 25px;
}

.common-table {
    padding-top: 1%;
    font-style: normal;
    margin: 0 60px;
    min-height: 350px;
    overflow-x: auto;
}

.ant-modal-body .common-table {
    padding-top: 3%;
}

.common-table table {
    border-left: 1px solid #eaecf0;
    border-radius: 16px;
    border-right: 1px solid #eaecf0;
    width: 100%;
}

.common-table table thead {
    background-color: #f0f0f0;
    height: 45px !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
}

.common-table table thead tr {

    font-size: 12px;
    font-weight: 600;
    padding-bottom: 10px;
    text-align: left;
    white-space: nowrap;
}

.common-table table thead tr th {
    text-align: center;
    color: #1ebaf2;
    font-size: 14px;
    font-weight: 500 !important;
    padding-left: 30px !important;
    padding-right: 20px;
}

.common-table table tbody tr {
    border-bottom: 1px solid #eaecf0;
}

.common-table table tbody tr td {
    text-align: center;
    color: #7a7474!important;
    font-size: 11px;
    font-weight: 600 !important;
    overflow: hidden;
    padding-left: 30px !important;
    padding-right: 20px !important;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 25px;
    padding-bottom: 25px;
    /* color: #fff !important; */
}

.common-table table tbody tr td img {
    filter: invert(100%) sepia(10%) saturate(104%) hue-rotate(232deg) brightness(116%) contrast(100%) !important;
}

.floorImg {
    width: 150px;
    ;
}

.min {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: red;
}

.max {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: green;
}

.avg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: blue;
}

.colortr {
    display: flex;
    justify-content: space-between;
}

.ant-modal-content .ant-modal-footer {
    display: none;
}

.ant-modal-body .common-table {
    min-height: auto;
    margin: 0;
}

.ant-form-item-control-input-content .checkbox-style {
    /* height: 210px!important; */
    overflow: hidden;
    overflow-y: scroll;
    white-space: nowrap !important;
    width: 100%;
}

.station table tbody tr td {
    text-align: center;
    color: #77818e;
    font-size: 11px;
    font-weight: 600 !important;
    overflow: hidden;
    /* padding-left: 30px!important;
    padding-right: 20px!important; */
    padding: 10px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* padding-top: 25px;
    padding-bottom: 25px; */
}

.station table thead tr th {
    text-align: center;
    color: #1ebaf2;
    font-size: 14px;
    font-weight: 500 !important;
    padding-left: 30px !important;
    padding-right: 20px;
}

.colormodel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 12vh;
    justify-content: center;
    margin: 0 60px;
}

.empty .empty-data {
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

}

.empty .empty-data .emptyimage {
    width: 100px;
    height: 100px;
}

.empty .empty-data p {
    margin-top: 10px;
    font-size: 18px;
    color: #888;
}

.ant-tabs-tab-btn {
    color: #fff !important;
}

.ant-tabs-tab-btn:hover{
    color: #00b1f0 !important;
}