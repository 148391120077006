.dashboard-card {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
}

.dashboard-card Button {
    width: 100%;
    font-size: 11px;
    border-radius: 3px !important;
}

.dashboard-item-image {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.card-start .ant-card-body {
    height: 360px;
    background-color: #333333;
    overflow: scroll;
}

.view-btn {
    border-radius: 50px !important;
}

.flex-align {
    display: flex !important;
}

@media only screen and (max-width: 600px) {
    .dashboard-card Button {
        width: 100%;
        font-size: 11px;
    }

    .dashboard-card {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .create-plc-form {
        padding-left: 20px;
        padding-right: 20px;
    }
}















/* Sub head */

.sub-head {
    padding: 25px 40px;
    padding-right: 10px;
}

.sub-head h2 {
    font-size: 16px;
    font-weight: 550 !important;
    letter-spacing: 0.5px;
    color: #00B1F0;
}

.sub-head img {
    width: 15px;
}

.sub-head .color-btn {
    color: #fff;
    background-color: #00B1F0;
    font-size: 12px !important;
    border-radius: 15px;
    width: 90%;
}

.sub-head-buttons{
    display: flex;
    justify-self: flex-end;
}

.sub-head Button,
.sub-head Button:focus {
    color: #fff;
    background-color: #00B1F0;
    font-size: 13px !important;
    border-radius: 3px;
    border: 1px solid #00B1F0;
    /* width: 80%; */
    margin-right: 20px;
}

.sub-head Button:hover {
    color: #fff;
    background-color: #006A8F;
    font-size: 13px !important;
    border-radius: 3px;
    border: 1px solid #006A8F;
    margin-right: 20px;
}

.sub-head img {
    padding-right: 10px;
    width: 25px;
}

.border h5 {
    border: 2px solid red;
    margin: 15px;
    padding: 10px;
    padding-right: 0px !important;
    margin-top: -5px;
}

.sub-head .ant-select {
    width: 250px;
    margin-right: 20px;
    text-align: left;
}



.sub-head .ant-select .ant-select-selector{
    border-radius: 15px !important;
    border: 1px solid #00B1F0;
    height: 32px;
}

.sub-head .anticon.anticon-swap-right, .anticon.anticon-calendar{
    color: white !important;
}

/* Sub head */


.green-btn {
    background-color: green !important;
    color: #fff !important;
    border: 1px solid green !important;
    border-radius: 15px !important;
}

.pale-green-btn {
    background-color: rgb(61, 244, 61) !important;
    color: #fff !important;
    border: 1px solid rgb(61, 244, 61) !important;
    border-radius: 15px !important;
}

.red-btn {
    background-color: red !important;
    color: #fff !important;
    border: 1px solid red !important;
    border-radius: 15px !important;
}

.blue-btn {
    background-color: #00B1F0 !important;
    color: #fff !important;
    border: 1px solid #00B1F0 !important;
    border-radius: 15px !important;
}
.green-btn {
    background-color: #00f020 !important;
    color: #fff !important;
    border: 1px solid #00f020 !important;
    border-radius: 15px !important;
}
.light-red {
    font-size: 12px;
    border-radius: 15px !important;
    padding-top: 5px;
    color: #00B1F0;
    font-weight: 500;
    height: 35px !important;
}

.light-blue {
    font-size: 12px;
    margin-top: -20px;
    border-radius: 15px !important;
}

.pale-red {
    background-color: rgb(243, 66, 66) !important;
    color: #fff !important;
    border: 1px solid rgb(243, 66, 66) !important;
    border-radius: 15px !important;
}
.pale-yellow {
    background-color: #FFBF00!important;
    color: #fff !important;
    border: 1px solid #FFBF00 !important;
    border-radius: 15px !important;
}
.pale-green {
    background-color: rgb(61, 244, 61) !important;
    color: #fff !important;
    border: 1px solid rgb(61, 244, 61) !important;
    border-radius: 15px !important;
}

button {
    font-weight: 500 !important;

}

.light-danger {
    background-color: red !important;
    color: red !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
    position: absolute;
    right: -15px;
    top: 12px;
}

.light-success {
    background-color: rgb(61, 244, 61) !important;
    color: rgb(61, 244, 61) !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
    position: absolute;
    right: -15px;
    top: 12px;
}

.dashboard-sensor-txt-success {
    font-size: medium;
    font-weight: bold;
    color: rgb(3, 95, 3);
}

.dashboard-sensor-txt-danger {
    font-size: medium;
    font-weight: bold;
    color: white;
}

.no-ptr:hover {
    cursor: default;
}

.product-name {
    color: #000;
    font-size: 14px;
    position: absolute;
    top: 33px;
    left: 37% !important;
}

/* .ant-select-selector {
    color: #00B1F0 !important;
    background-color: #fff !important;
    font-size: 12px !important;
    border-radius: 15px !important;
    border: 1px solid #00B1F0 !important;
} */

.id-range {
    font-size: 13px;
    margin-top: -5px;
    color:#fff;
}
.date-icon{
    width: 250px;
}
.date-icon img {
    width: 20px;
    margin-top: -3px;
}

.card-start a {
    width: 100%;
}

.warning-btn-green {
    position: absolute;
    top: -7px;
    right: -16px;
    color: green;
}

.warning-btn-red {
    position: absolute;
    top: -7px;
    right: -16px;
    color: red;
}

.card-hide {
    z-index: -99;
}

.no-sensor h2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}

.no-sensor {
    text-align: center !important;
}

.nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;

}

.nodata h1 {
    font-size: 24px;
    font-weight: 550 !important;
    letter-spacing: 0.5px;
    color: #00B1F0;
}
.sub-head .back {
    padding-right: 10px;
    width: 38px;
    margin-left: 0px;
    color: white;
}
.left-to-right-animation {
    position: relative;
    animation: slideInLeftToRight 0.5s forwards;
  }
  
  @keyframes slideInLeftToRight {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

.random-fit .ant-select-selector {
    height: 35px;
    overflow: scroll;
}
.sub-head .ant-col .backArrow .inside-head{
display: flex;
}
.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    margin: 0;
    padding: 0;
}
.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .common-table{
    margin: 0;
    padding: 0; 
}
.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .hover {
    min-height: fit-content !important;
}

.ant-picker.metrics-filter-date {
    background-color: #333333;
    border: none;
    color: grey;
}

.ant-picker.metrics-filter-date .ant-picker-suffix {
    color: grey;
}

.ant-picker.metrics-filter-date .ant-picker-input>input {
    color: grey;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #333333 !important;
    border-radius: 3px !important;
    border: none !important;
    font-size: 13px;
}

.ant-row.limit-status h1 {
    color:#00B1F0;
}
.ant-row.limit-status span {
    color:#fff;
}

.ant-checkbox+span {
    color: white;
}

.tile {
    color: white;
}