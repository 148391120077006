@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    overflow-x: hidden;
    font-family: 'poppins' !important;
    background: #1c1c1c !important;
    color: #fff;
}

* {
    padding: 0px;
    margin: 0px;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}

.txt-center {
    text-align: center;
}

.ant-card {
    border-radius: 15px !important;
}

.ant-spin-text {
    font-size: 16px;
    margin-top: 10px;
}

.dashboard-loading {
    margin-top: 200px;
}


/* scrollbar start*/

::-webkit-scrollbar {
    width: 3px;
    display: none;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #3157F6;
    border-radius: 10px;
}

/* scrollbar End*/


.anticon.anticon-arrow-left.back {
    color: white;
}

.ant-card-body h2 {
    color: #00b1f0;
}

text,
.current-value {
    fill: rgb(255 255 255) !important;
    color: #fff !important;
}

.ant-select-selection-item-content {
    color: #00b1f0 !important;
}

.txt-label h4 {
    color: #fff !important;
}

#floorName {
    background-color: #333333;
    border: none;
    color: grey;
}

.header-logos {
    width: 190px !important;
    padding: 10px;
}

.ant-btn:hover {
    background-color: #006A8F;
    color: #ffffff; 
  }

  .ant-select-selection-placeholder {
    color: white !important;
  }