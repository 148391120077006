a .anticon{
    font-size: initial;
    vertical-align: middle;
    border-style: none;
    color: #77818e;
    /* padding: 3%; */
}
.model .ant-form {
    display: flex !important;
    justify-content: center ;
    flex-wrap: wrap;
}

*{
    font-family: inherit;
}