.ant-card-body {
  padding: 15px !important;
  background-color: #333333;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 15px !important;
}

.ant-card-body:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 15px !important;
}

.dashboard-item-images {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  margin-top: 10px;
}