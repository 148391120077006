.size {
  width: 80px;
}

.size:hover {
  width: 225px;
}
:root {
  --main-color: white;
}
.dynamic-color{
  background-color: var(--main-color);
}
.dote1 {
  /* background: linear-gradient(to right, #00ff00, #ffff00, #ff0000); */
  /* background: linear-gradient(to right, #00ff00, #66ff00, #ffff00, #ff6600, #ff0000); */
  /* background: #080606;; */
  position: relative;
  z-index: 999 !important;
  background-color: black;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}
.content1{
  z-index: -999;
    position: relative;
  filter: blur(5px);
  width: 20px;
  height: 20px;
  border-radius: 50px;
  transform: scale(2.2);
  /* background: green; */
}
.img-size {
  width: 20px;
  height: 20px;
  margin-top: 8px;
}

.contentbox {
  background-color: rgb(213 212 212);
  color: #000;
  height: 100%;
  padding: 2%;
}

.iconside {
  height: 100%;
  background-color: #63c663;
  color: #000;
  border-radius: 6px 0px 0px 6px;
  text-align: center;

}

.draggable-element {
  position: absolute;
  transition: transform 0.8s ease-out;
  cursor: grab;

}

.dragging {
  cursor: grabbing;
}

.draggable-container {
  position: relative;

}

.img-card .dropdown {
  position: absolute;
  display: flex;
  /* right: 4%; */
  align-items: center;
  z-index: 1;
  margin: 1% 0px 0px 1050px

}

.img-card {
  /* padding-right: 4%; */
width: 1400px;
  height: 700px;
  /* margin: 0 0 0 5%; */
  box-shadow: 0 4px 8px rgba(0,0,0,.15);

}
.model-buttons h1 {
  font-size: 17px;
  font-weight: 600;
}
.model-buttons{
  padding: 9%;

}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 3px !important;
  border: #00B1F0;
  border: none !important;
  height: 32px;
  color: #fff;
  overflow: scroll;
  /* border: 1px solid #d0d5dd!important;
  border-radius: 5px!important;
  box-shadow: 0 0 1px #00000029;
  font-size: 12px!important;
  height: auto;
  padding: 5px 10px; */
}
.ant-btn-primary {
  font-size: 12px;
  min-width: 100%;
}
.model-buttons h1 span {
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
}
.model-buttons .ant-form .ant-form-item .ant-row .ant-col .ant-form-item-control-input {
  /* width: 350px !important; */
}

 .ant-form-item-control-input-content .ant-btn-primary {
    color: #00B1F0;
    background-color: #fff;
    font-size: 13px !important;
    border-radius: 15px;
    border: 1px solid #00B1F0;
    /* width: 80%; */
    margin-right: 20px;
}
.ant-row .secondpart{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-row .secondpart h3{
color: #23bbf1
};
.ant-modal-root .ant-modal-wrap .mapping .ant-modal-content {
  border-radius: 30px;
    border: 2px solid #00b1f0;
}
.mapform .ant-form-item .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content .ant-select {
  max-width: 100%;
}
.ant-modal-root .ant-modal-wrap .mapping .ant-modal-content .ant-modal-body{
  padding: 0;
}
.group .ant-checkbox-group{
  flex-direction: column;
    display: flex;
}
.group .ant-checkbox-group .ant-checkbox-group-item{
  /* padding-bottom: 10px; */
  padding: 4% 0;
  color: #00b1f0;
}
.group .checkbutton{
  padding-top: 10px;
  color: #00b1f0;
  font-size: 15px;
}
.group{
  padding-left: 2%;
}
.img-card button{
  border: 1px solid #00b1f0;
  margin-left: 8px;
  border-radius: 13px;
  width: 36px;
  height: 36px;
  color: #00b1f0;
  background: white;
}
/* .img-card button :hover{
  border: 1px solid #00b1f0;
    border-radius: 13px;
    width: 36px;
    height: 36px;
    color: white;
    background: #00b1f0;
} */
.card {
  /* background-color: #f0f0f0; */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 0 0 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.card .machine h2 {
  color: #00b1f0;
  margin-top: 0;
  font-size: 14px;
  padding: 0 20px;
}
.card .sensor h2 {
  margin-top: 0;
  font-size: 14px;
}
.machine-id, .sensor-name {
  font-size: 14px;
  font-weight: bold;
  overflow-wrap: break-word;
  /* width: 140px; */
}
.pagination{
  height: 12vh;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly ;
  align-items: center !important;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
    background-color: #23bbf1;
    border: 1px solid #23bbf1;
}

.ant-pagination-item-active a,
.ant-pagination-item-active:hover a {
    color: #fff;
}


.card .hover-table {
  /* padding-top: 1%; */
  font-style: normal;
  /* margin: 0 60px; */
  min-height: 350px;
  overflow-x: auto;
}
/* .ant-modal-body .common-table{
  padding-top: 3%;
} */
.card .hover-table table {
  border-left: 1px solid #eaecf0;
  border-radius: 16px;
  border-right: 1px solid #eaecf0;
  width: 100%;
}
.card .hover-table table thead {
  background-color: #f0f0f0;
  height: 45px!important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}
.card .hover-table table thead tr {

  font-size: 12px;
  font-weight: 600;
  /* padding-bottom: 10px; */
  text-align: left;
  white-space: nowrap;
}
.card .hover-table table thead tr th {
  text-align: center;
  color: #1ebaf2;
  font-size: 14px;
  font-weight: 500!important;
  /* padding-left: 30px!important; */
  /* padding-right: 20px; */
}
.card .hover-table table tbody tr {
  border-bottom: 1px solid #eaecf0;
}
.card .hover-table table tbody tr td {
  text-align: center;
  color: #77818e;
  font-size: 11px;
  font-weight: 600!important;
  overflow: hidden;
  /* padding-left: 30px!important; */
  /* padding-right: 20px!important; */
  /* padding: 10px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding-top: 25px; */
  /* padding-bottom: 25px; */
}
.image-mark{
  display: flex;
  justify-content: center;
}
.machine-id li{
  color: #77818e;
  font-size: 11px;
  padding: 10px 0;
  font-weight: 600!important;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
}
.machine-id li:not(:first-child) {
  border-top:none;
}
.image-mark .ant-col .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap{
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane, .ant-tabs-left>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding-left: 0px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}
.ant-tabs-tab:hover {
  color: #00b1f0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active{
  border: 2px solid #00b1f0;
  background-color: #00b1f0;
  box-shadow: 0 4px 8px rgba(0,0,0,.15);
}
.image-mark .ant-col .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list { 
  width: 180px;
}
.machine .station{
  font-weight: 600;
    color: #637981 !important;
    border-bottom: 1px solid #eaecf0;
}
.empty-data {
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

}

.empty-data .emptyimage {
  width: 100px;
  height: 100px;
}

.empty-data p {
  margin-top: 10px;
  font-size: 18px;
  color: #888;
}

.active {
  border-bottom: 3px solid #00b1f0;
  color: #00b1f0 !important;
  font-weight: 600 !important;
}

.inactive {
  color: rgb(193, 191, 191) !important;
}

