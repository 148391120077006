.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    padding: 5px 20px;
    padding-right: 40px;
    background-color: #3636397d;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.logo-area {
    width: 15%;
}

.db-area h3 {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-left: -100px;
}

.dashboard-link a p img {
    width: 15px;
    margin-right: 10px;
    color: #fff;
}

.dashboard-link a p {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    color: #fff;
}

.dashboard-link a .mapbutton {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    color: #fff;
    padding-left: 13px;
}

.settingicon {
    margin-top: 7px;
    color: #fff;
    margin-left: 10px;
}

.settingicon a .anticon {
    color: white !important;

}

.header .dashboard-link {
    display: flex;
}

.settingpng {
    width: 25px;
    height: 25px;
}

.header-logo {
    width: 190px !important;
    padding: 10px;
}

.header-logo2 {
    display: none;
}

@media screen and (max-width: 600px) {
    .db-area h3 {
        display: none;
    }

    .header-logo {
        width: 160% !important;
    }

    /* .header-logo2 {
        display: block;
        width: 30% !important;
        margin-left: -20px;
    } */

}