.createform{
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
}
.floor-titel {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 150px 20px;
}
.floor-titel .title h1 {
    /* font-family: Inter; */
    font-size: 25px;
    font-weight: 600;
    line-height: 36px;
    margin-left: 10px;
    margin-top: 15px;
}
.floor-titel .title p {
    color: #475467;
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}
.floor-titel .ant-btn {
    align-items: center;
    background: #3166ff;
    border-radius: 5px;
    color: #fff;
    display: flex;
    flex-direction: row;
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    height: 38px;
    margin-top: -15px;
    padding: 8px 16px;
}
.floor-titel .txt-right .save-img {
    height: 20px;
    width: 20px;
}
.floor-form {
    padding: 20px 150px;
}


.ant-form-item-control-input {
    align-items: center;
    display: flex;
    min-height: 32px;
    position: relative;
}
.ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
}
.floor-form .ant-input {
    border: 1px solid #d0d5dd!important;
    border-radius: 6px;
    height: 38px;
    max-width: 350px;
    padding: 10px 11px;
}
.ant-col {
    max-width: 100%;
    min-height: 1px;
    position: relative;
}
.ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
}
.ant-form-item-label>label {
    align-items: flex-start;
    color: #344054!important;
    display: flex;
    /* font-family: Inter!important; */
    font-size: 13px;
    font-weight: 500;
    justify-content: flex-start;
    line-height: 40px;
    width: 400px;
}
.ant-form-item-control-input {
    align-items: center;
    display: flex;
    min-height: 32px;
    position: relative;
}
.ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
}
.floor-form .ant-input {
    border: 1px solid #d0d5dd!important;
    border-radius: 6px;
    height: 38px;
    max-width: 350px;
    padding: 10px 11px;
}
.floor-form .txt-label {
    align-items: center;
    display: flex;
    color: white;
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    justify-content: flex-start;
    width: 400px!important;
}
.floor-form .floor-image {
    margin-bottom: 0;
}
.floor-form .size_image {
    /* height: 130px; */
    object-fit: cover;
    width: 350px;
}
.floor-form .ant-upload {
    color: #3166ff!important;
    cursor: pointer;
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}
.floor-form .img, .floor-form .size_image {
    align-items: center;
    background: transparent;
    display: flex;
    flex-direction: column;
}
.floor-form p {
    color: #000;
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-top: -15px;
}
.floor-form .img {
    padding: 10px;
    width: 350px;
    color: #00b1f0;
}
.floor-form .img h3{
    color: #00b1f0; 
    font-size: 13px;
}
.floor-form .img p{
    color: #acacac;
    font-size: 11px;
}
.floor-form hr {
    border: 1px solid #eaecf0;
    margin-bottom: 25px;
}
.ant-select {
    max-width: 350px;
}
.floor-form h5 {
    align-items: center;
    cursor: pointer!important;
    display: flex;
    height: 38px;
    justify-content: flex-start;
}
.floor-form h5 {
    color: #3166ff;
    font-size: 14px;
}
.save .ant-col .ant-btn .save-img{
    padding: 0 9px 0px 0px;
}
.save .ant-col.ant-btn{
    border-radius: 5px
}
.formfloor .save{
    padding-top: 3%;
}
.floor-image .ant-btn-primary {
    color: #00B1F0;
    background-color: #fff;
    font-size: 13px !important;
    border-radius: 15px;
    border: 1px solid #00B1F0;
    width: 80%;
    margin-right: 20px;
}
.img .anticon {
    padding-left: 2px;
}
.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
    width: 34px;
    line-height: 34px;
}
.changebutton{
    margin: 0 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #00B1F0 !important;
    border: none !important;
    border-radius: 3px !important;
    color: #fff !important;
    text-align: center;
}
