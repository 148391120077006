.create-plc-form {
    width: 100%;
    padding-top: 100px;
}

.create-plc-form input {
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: none;
    border: 1px solid rgb(199, 198, 198);
}

.create-plc-form select {
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    border: none;
    border: 1px solid rgb(199, 198, 198);
    font-size: 11px;
}

.create-plc-form .form-label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
}

::placeholder {
    font-size: 10px;
}

.create-plc-form .btn-primary {
    background-color: #00B1F0;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 30px;
}

.create-plc-form h1 {
    font-size: 17px;
    font-weight: 600;
}

.create-plc-form .btn-primarys {
    background-color: #c2bfbf;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 30px;
}

.sub-form {
    margin-top: -40px !important;
}

.space {
    margin-top: 50px !important;
}