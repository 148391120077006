.metrics-line-graph-container {
  margin: 32px 5% 0 5%;
  padding: 2px 32px 32px 32px;
  background-color: white;
  box-shadow: 0px 2px 19px -4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.metrics-line-chart {
  height: 400px;
}

.metrics-header {
  margin: 64px 5% 0 5%;
  padding: 24px;
  background-color: white;
  box-shadow: 0px 2px 19px -4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
}

.metrics-plc-name {
  height: 24px;
  font-size: 24px;
  font-weight: bold;
}

.metrics-plc-status {
  border-radius: 50%;
  height: 24px;
  width: 24px;
}

.metrics-filter-container {
  margin-bottom: 16px;
  padding-left: 16px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.metrics-filter-group {
  display: flex;
  margin-top: 16px;
}

.metrics-filter-date {
  height: 32px;
  text-align: center;
  background-color: #333333 !important;
}

.metrics-select-filter-date {
  background-color: #333333 !important;
}

.sensor-list-dropdown select {
  height: 36px;
  padding: 4px;
  width: 150px;
  margin-right: 32px;
  outline: none;
}

.metrics-gauge-list-container {
  margin-top: 32px;
  margin-left: 5%;
  margin-bottom: 5%;
}

.gauge-list-header {
  margin: 64px 5% 0 0;
  padding: 2px 32px 4px 32px;
  background-color: white;
  box-shadow: 0px 2px 19px -4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 4px;
}

.gague-list-container {
  display: flex;
  flex-wrap: wrap;
}

.sensor-gauge-container {
  width: 20%;
  margin: 16px;
  padding-top: 25px;
  height: 320px;
  background-color: white;
  box-shadow: 0px 2px 19px -4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.sensor-gauge-name {
  margin: 24px;
}

.sensor-gauge-value {
  margin-top: 100px;
  text-align: center;
}

.sensor-gauge-unit {
  font-size: 16px;
}

.head-part {
  padding: 40px;
  padding-bottom: 20px;
}

.head-part h1 {
  font-size: 17px;
  text-align: center;
  color: white;
  /* font-weight: 900; */
  white-space: nowrap !important;
}

.over-view {
  padding: 40px;
  padding-top: 0px !important;
}

.over-view h1 {
  font-size: 18px;
  /* font-weight: 900; */
  white-space: nowrap !important;
  border: 1px solid skyblue;
  padding: 5px 25px 5px 25px;
  background-color: skyblue;
  border-radius: 50px;
  margin-top: -7px;
}

.over-view input {
  width: 100%;
  height: 30px;
  border-radius: 3px;
  border: none;
  border: 1px solid rgb(199, 198, 198);
}

.over-view select {
  margin-left: 20px;
  width: 100%;
  font-size: 12px;
  height: 30px;
  border-radius: 3px;
  border: none;
  background-color: #ffffff1a;
  color: #fff;
}

input {
  color: #fff !important;
}

input[type="date"] {
  font-size: 12px;
}

.graph {
  padding-left: 40px;
  padding-right: 40px;
}

.graph h4 {
  color: #fff;
}

.temp-value,
.temp-name {
  font-size: 18px;
  font-weight: 600;
}

.temp-name {
  min-height: 70px !important;
  padding-top: 10px;
  /* white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis; */
}

#gauge-chart4 {
  height: auto !important;
}

.ant-tabs-nav-wrap {
  margin-left: 50px !important;
  margin-top: 40px;
}

.low {
  /* color: green; */
  position: absolute;
  left: 10%;
  font-size: 12px;
  margin-top: -15px;
}

.high {
  /* color: red; */
  position: absolute;
  right: 10%;
  font-size: 12px;
  margin-top: -15px;
}

.back {
  font-size: 20px;
  margin-left: 20px;
}

.common-head {
  margin-top: 30px;
  padding-right: 10px;
  padding-left: 40px;
}

.common-head a {
  margin: 20px;
  color: #000;
  font-size: 16px;
  font-weight: 600 !important;
}

.arg {
  position: absolute;
  left: 5px;
  cursor: pointer;
}

.tile {
  font-size: 13px;
  margin-top: 130px;
}

.active {
  border-bottom: 3px solid #00b1f0;
  color: #00b1f0 !important;
  font-weight: 600 !important;
}

.csv-d {
  position: absolute;
  right: 40px;
  top: 120px;
  padding: 5px 25px;
  font-size: 12px;
  color: #fff;
  background-color: #00b1f0;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.csv-de {
  padding: 6px 25px;
  font-size: 12px;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  background-color: #00b1f0;
  border: none;
  border-radius: 3px;
}

.product-names {
  font-size: 16px !important;
  position: absolute !important;
  top: -22px !important;
  right: 0% !important;
}


.limit-status h1 {
  font-size: 15px;
  text-align: left;
  font-weight: 600;
}

.limit-status h1 span {
  font-size: 15px;
  text-align: left;
  font-weight: 500;
}

.one {
  display: block !important;
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.guage-color-green .current-value {
  /* fill: grey !important; */
}

.guage-color-yellow .current-value {
  fill: #FFBF00 !important;
}

.guage-color-red .current-value {
  fill: red !important;
}

.ant-select-disabled .ant-select-selector {
  background-color: rgb(234, 234, 234) !important;
}

.ant-picker.ant-picker-range {
  background-color: #333333;
  border: none;
}

.anticon.anticon-swap-right,
.anticon.anticon-calendar {
  color: gray;
}